.custom-input {
  height: 38px;
  /* Match the height of the Select element */
  padding: 0 10px;
  /* Add consistent padding */
  font-size: 16px;
  /* Adjust font size if necessary */
  border: 1px solid #ced4da;
  /* Optional: match border style */
  border-radius: 4px;
  /* Optional: match border radius */
  box-sizing: border-box;
  /* Ensure padding is included in height */
}

.text-center {
  text-align: center;
}

.cellTopHeader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.cellstylegridGW,
.cellTopHeader,
.text-center {
  display: flex;
  align-items: center;
  /* Vertically center the content */
  justify-content: center;
  /* Horizontally center the content */
  text-align: center;
  /* Center-align text inside */
}

.cellstylegrid {
  background: #ffe4bb;
  color: #333;
}

.cellstylegridG {
  background: rgb(152, 219, 245);
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridNeg {
  background: rgb(240, 69, 94);
  color: #070000;
  border-bottom: "2px solid black";
  font-weight: 630;
}

.cellstylegridPostve {
  background: rgb(107, 241, 73);
  color: #070000;
  border-bottom: "2px solid black";
  font-weight: 630;
}

.cellstylegridDist {
  background: #edd280;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridCost {
  background: #70a2df;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridCustomer {
  background: #d490e4;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridOrd {
  background: #c0b66b;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridVeh {
  background: #965d72;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridVehType {
  background: #ebed68;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridRemarks {
  background: #e38dbc;
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridWeight {
  background: rgb(116, 204, 219);
  color: #333;
  border-bottom: "2px solid black";
}

.cellstylegridVolume {
  background: rgb(224, 218, 158);
  color: #333;
  border-bottom: "2px solid black";
}

/* Adjusting font size for headers */
.ag-theme-alpine .ag-header-cell {
  font-size: 12px;
  /* Change this to your desired size */
}

/* Adjusting font size for cells */
.ag-theme-alpine .ag-cell {
  font-size: 12px;
  /* Change this to your desired size */
}

/* Optional: Adjust font size for row header if needed */
.ag-theme-alpine .ag-row {
  font-size: 12px;
  /* Adjust this as well */
}

.expanding-div {
  width: 0;
  height: 0;
  margin: 20px auto;
  background-color: #4caf50;
  border-radius: 8px;
  transition: all 0.5s ease;
  /* Smooth transition */
  transform: scale(1);
  /* Default scale */
  opacity: 0.8;
  /* Slight transparency */
}

.expanding-div.expanded {
  width: 300px;
  /* New width */
  height: 300px;
  /* New height */
  transform: scale(1.2);
  /* Slight zoom effect */
  opacity: 1;
  /* Full opacity */
  background-color: #2196f3;
  /* Change color */
}


.chart-container {
  transform: scaleY(0); /* Initially collapsed */
  transform-origin: top; /* Expand from the top */
  opacity: 0; /* Initially hidden */
  transition: transform 0.5s ease, opacity 0.5s ease; /* Smooth animation */
  max-height: 0; /* Prevent affecting layout when hidden */
  overflow: hidden; /* Hide overflowing content */
}

.chart-container.visible {
  transform: scaleY(1); /* Fully expanded */
  opacity: 1; /* Fully visible */
  max-height: 190vh; 
}

