/* Add a border to the right of each cell */
.ag-theme-balham .ag-cell {
  border-right: 1px solid #ccc; /* You can change the color */
}

/* Remove the border from the last column */
.ag-theme-balham .ag-header-cell:last-child,
.ag-theme-balham .ag-cell:last-child {
  border-right: none;
}

/* Increase font size and center-align grid cells */
.ag-theme-balham .ag-cell {
  font-size: 13px; 
  /* text-align: center; */
}


.bold-row {
  font-weight: bold;
}
